.ss-intro {
  .ss-row {
    padding: 200px;
  }
  display: grid;
  margin: 10px 10px 40px 10px;

  .ss-intro__title {
    display: flex;
    gap: 10px;
    margin: 0 0 32px 0;
    color: #081c15;
    text-shadow: 0px 4px 0px rgba(8, 28, 21, 0.12);

    h1 {
      display: flex;
      gap: 10px;
      font-family: "Frank Ruhl Libre", sans-serif;
      font-size: 70px;
      font-weight: 700;
    }

    .glitch {
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      text-shadow: 0.05em 0 0 #a8a8a8, -0.03em -0.04em 0 #d3d3d3,
      0.025em 0.04em 0 #f5f5f5;
      animation: glitch 1500ms infinite;
    }

    .glitch span {
      position: absolute;
      top: 0;
      left: 0;
    }

    .glitch span:first-child {
      animation: glitch 1200ms infinite;
      clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
      transform: translate(-0.04em, -0.03em);
      opacity: 0.75;
    }

    .glitch span:last-child {
      animation: glitch 800ms infinite;
      clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
      transform: translate(0.04em, 0.03em);
      opacity: 0.75;
    }

    @keyframes glitch {
      0% {
        text-shadow: 0.05em 0 0 #a8a8a8, -0.03em -0.04em 0 #d3d3d3,
        0.025em 0.04em 0 #f5f5f5;
      }
      50% {
        text-shadow: 0.05em 0.035em 0 #a8a8a8, 0.03em 0 0 #d3d3d3,
        0 -0.04em 0 #f5f5f5;
      }
      100% {
        text-shadow: -0.05em 0 0 #a8a8a8, -0.025em -0.04em 0 #d3d3d3,
        -0.04em -0.025em 0 #f5f5f5;
      }
    }

    .waving-hand {
      color: #081c15;
      font-family: "Frank Ruhl Libre", sans-serif;
      font-size: 52px;
      font-weight: 700;
    }
  }

  .ss-intro__description {
    margin: 0 0 32px 0;
    color: #212529;
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
  }

  .ss-intro__tagline {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
  }

  .ss-intro__download-cv {
    width: fit-content;

    a {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      text-decoration: none;
      margin-top: 20px;
      border-bottom: 1px dashed #053969;
    }
  }

  display: grid;
}
