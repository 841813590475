@import url("styles/header.scss");
@import url("styles/intro.scss");
@import url("styles/portfolio.scss");

* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
}

.ss-portfolio__skills {
  max-width: 1170px;
  margin: 160px auto 120px;
  padding: 0 20px;

  .ss-portfolio__skills-header {
    color: #212529;
    text-shadow: 0px 4px 0px rgba(8, 28, 21, 0.12);
    font-family: "Frank Ruhl Libre", sans-serif;
    font-size: 66px;
    font-weight: 400;
    margin: 60px auto;
  }

  .ss-portfolio__skills-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    flex-wrap: wrap;

    .ss-portfolio__skill {
      display: flex;
      gap: 16px;
      align-items: center;
      padding: 20px;
      border-radius: 22px;
      border: 1px solid #ededed;
      background: #fafafa;

      h4,
      p {
        margin: 0;
      }

      .ss-portfolio__skill-title {
        color: #212529;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
      }

      .ss-portfolio__skill-description {
        color: #464646;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }

      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}

.ss-hire-me {
  background: #111;

  .ss-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h4 {
    text-align: center;
    color: #dbdbdb;
    text-shadow: 0px 4px 0px rgba(159, 159, 159, 0.12);
    font-family: "Frank Ruhl Libre", sans-serif;
    font-size: 66px;
    font-weight: 400;
  }

  .ss-hire-me__work-status {
    display: flex;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    gap: 10.286px;
    border-radius: 25.071px;
    border: 0.643px solid #fff;
    background: rgba(84, 84, 84, 0.04);
    color: #fff;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 175px;
    margin-bottom: 32px;

    img {
      height: 10px;
      height: 10px;
    }
  }

  .ss-hire-me__header {
    margin-bottom: 48px;
  }

  .ss-hire-me__cta {
    display: flex;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 10.286px;
    border-radius: 50px;
    border: 1px solid #fff;
    background: #fff;
    color: #111;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  .ss-hire-me__cta-description {
    color: #fff;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
  }

  .ss-hire-me__social-links {
    display: flex;
    gap: 30px;
    margin: 132px auto 172px;
    max-width: 1179px;
    width: 96%;
    justify-content: space-between;
    align-items: center;

    a {
      color: #fff;
      text-decoration: none;
      padding: 20px 0;
      border-bottom: 1px solid #737373;
      width: 96%;
      max-width: 96%;
    }

    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      div {
        display: flex;
        align-items: center;
        gap: 16px;
        color: #ededed;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
      }
    }
  }

  .ss-divider {
    border-bottom-color: #2D2D2D;
  }
}

.ss-footer {
  display: flex;
  justify-content: center;
  background: #111;
  padding: 60px 0;

  span {
    margin: 75px 0;
    color: #ededed;
    font-family: "Inria Serif";
    font-size: 24px;
    font-weight: 700;
  }
}

.ss-resume {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@import url("styles/responsive.scss");
