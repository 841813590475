.ss-header {
  .ss-container {
    .ss-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      margin: 0 24px;
    }
  }

  .ss-header__logo-link {
    text-decoration: none;
  }

  .ss-header__logo {
    padding: 10px;
    color: #212529;
    font-family: "Inria Serif";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    animation: animate 1s forwards;
  }

  @keyframes animate {
    from {
      opacity:0;
      transform: scale(0.8)
    }
    to {
      opacity: 1;
      transform: scale(1)
    }
  }

  .ss-header__navbar__links {
    display: flex;
    gap: 16px;
    animation: animate 1s forwards;

    a {
      transition: 0.6s ease-in-out;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.ss-divider {
  border-bottom: 1px solid #ededed;
  margin-top: 5px;
  max-width: 96%;
  margin: auto;
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  z-index: 9999;
  animation: slideDown 1s forwards;
}

@keyframes slideDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}