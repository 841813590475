/* Tablet styles */
@media (max-width: 1024px) {
  .ss-intro  {
    .ss-row {
      padding: 20px;
    }

    .ss-intro__title {
      h1 {
        font-size: 38px;
      }


      span {
        font-size: 36px;
      }
    }

    .ss-intro__description {
      font-size: 15px;

      p {
        line-height: 20px;

        &:first-child {
          margin-bottom: 10px;
        }
      }
    }

    .ss-intro__tagline {
      font-size: 15px;
    }
  }

  .ss-portfolio__skills {
    padding: 20px;

    .ss-portfolio__skills-header {
      font-size: 38px !important;
    }

    .ss-portfolio__skills-list{
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  .ss-hire-me {
    h4 {
      font-size: 38px !important;
    }

    .ss-hire-me__social-links {
      flex-direction: column;
    }
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .ss-intro {
    .ss-intro__title {
      h1 {
        flex-direction: column;
      }

      span {
        display: none;
      }
    }
  }

  .ss-portfolio__projects {
    grid-template-columns: repeat(1, 1fr);
  }

  .ss-portfolio__skills {
    padding: 20px;

    .ss-portfolio__skills-list{
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}

@media (max-width: 465px) {
  .ss-header  {
    .ss-container {
      .ss-row {
        flex-direction: column;
      }
    }
  }
}
